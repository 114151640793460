<template>
    <RouterView />
</template>
  
  <script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
  import { onBeforeRouteUpdate } from 'vue-router'
  import { useBreakpoint } from 'vuestic-ui'

  const breakpoints = useBreakpoint()
  
  const isMobile = ref(false)
  const isTablet = ref(false)
  
  const onResize = () => {
    isMobile.value = breakpoints.smDown
    isTablet.value = breakpoints.mdDown
  }
  
  onMounted(() => {
    window.addEventListener('resize', onResize)
    onResize()
  })
  
  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })
  

  </script>
  
  <style lang="scss" scoped>
  // Prevent icon jump on animation
  .va-sidebar {
    width: unset !important;
    min-width: unset !important;
  }
  </style>
  